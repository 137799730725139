.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  overflow: hidden;
  background-color: #333;
}

.navbar a {
  float: left;
  font-size: 14px;
  font-weight: 700;
  font-family: "Work Sans";
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

/* px: ["12px", "32px"],
      py: 1,

      color: "#fff",
      backgroundColor: "#2b2b2b",
      textTransform: "uppercase",
      letterSpacing: "0.2em",
      ":hover": {
        color: "#2b2b2b",
        backgroundColor: "#FFC905",
      },, */

.dropdown .dropbtn {
  font-size: 14px;
  font-weight: 700;
  font-family: "Work Sans";
  border: none;
  outline: none;
  color: white;
  padding: 14px auto;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  height: 100%;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  color: #2b2b2b;
  background-color: #ffc905;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2b2b2b;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  font-family: "Work Sans";
  display: block;
  text-align: left;
}
.dropdown-content:hover a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  font-family: "Work Sans";
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ffc905;
  color: #2b2b2b;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (min-width: 600px) {
  .dropdown .dropbtn {
    font-size: 20px;
    font-weight: 700;
    font-family: "Work Sans";
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }

  .navbar a {
    float: left;
    font-size: 20px;
    font-weight: 700;
    font-family: "Work Sans";
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
}
